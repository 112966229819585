import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Container = styled.div`
    cursor: default;
    border: 1px solid #e5e5e5;
    transition: transform 0.5s ease;

    &:hover {
        transform: scale(1.1);
    }
`;

const StrongPoint = ({ icon, text }) => {
    return (
        <Container className="flex flex-col items-center p-10 rounded-xl w-[220px] h-[200px] xl:max-w-[180px] lg:min-w-[160px] md:max-w-[160px] sm:min-w-[140px]">
            <div className="rounded-full w-[55px] h-[55px] bg-customPink flex items-center justify-center">
                <FontAwesomeIcon icon={icon} className="text-white" />
            </div>
            <h5 className="font-light pt-3 text-center">{text}</h5>
        </Container>
    );
};

export default StrongPoint;
