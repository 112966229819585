import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import ScrollReveal from "scrollreveal";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Agence from "./pages/Agence";
import "./styles/App.css";
import AcheterLouer from "./pages/AcheterLouer";
import Vendre from "./pages/Vendre";
import GestionLocative from "./pages/GestionLocative";
import ChasseurAppartement from "./pages/ChasseurAppartement";
import DetailsId1 from "./components/DetailsMain/DetailsPageId/DetailsId1";
import DetailsId2 from "./components/DetailsMain/DetailsPageId/DetailsId2";
import DetailsId3 from "./components/DetailsMain/DetailsPageId/DetailsId3";
import DetailsId4 from "./components/DetailsMain/DetailsPageId/DetailsId4";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ScrollReveal().reveal(".reveal-bottom", {
            origin: "bottom",
            distance: "30px",
            duration: 1500,
            delay: 100,
            reset: false,
        });

        ScrollReveal().reveal(".reveal-right", {
            origin: "right",
            distance: "30px",
            duration: 1500,
            delay: 100,
            reset: false,
        });

        ScrollReveal().reveal(".reveal-left", {
            origin: "left",
            distance: "30px",
            duration: 1500,
            delay: 100,
            reset: false,
        });

        ScrollReveal().reveal(".reveal-top", {
            origin: "top",
            distance: "30px",
            duration: 1500,
            delay: 100,
            reset: false,
        });
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Agence />} />
                    <Route path="/acheter-louer" element={<AcheterLouer />} />
                    <Route path="/vendre" element={<Vendre />} />
                    <Route path="/details/1" element={<DetailsId1 />} />
                    <Route path="/details/2" element={<DetailsId2 />} />
                    <Route path="/details/3" element={<DetailsId3 />} />
                    <Route path="/details/4" element={<DetailsId4 />} />
                    <Route
                        path="/gestion-locative"
                        element={<GestionLocative />}
                    />
                    <Route
                        path="/chasseur-appartement"
                        element={<ChasseurAppartement />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
