import React from "react";
import Card from "../../Card";
import imgDetails1 from "../../../assets/Image/Details1/Photo1.jpg";
import imgDetails2 from "../../../assets/Image/Details2/Photo1.jpg";
import imgDetails3 from "../../../assets/Image/Details3/Photo1.jpg";
import imgDetails4 from "../../../assets/Image/Details4/Photo1.jpg";
import { useNavigate } from "react-router-dom";

const CurrentSales = () => {
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/details/${id}`);
    };

    return (
        <div className="flex flex-col px-48 my-24 mx-auto border xl:px-12 lg:px-10 md:px-8 sm:px-7">
            <h3 className="text-2xl mb-5 reveal-left">En vente actuellement</h3>
            <div className="flex flex-wrap justify-start gap-4 ml-10 reveal-bottom sm:ml-0 sm:flex-col">
                <Card
                    id="1"
                    status="Achat"
                    img={imgDetails1}
                    title="Appartement"
                    description="Appartement 4 pièces centre ville poitiers"
                    adress="Place du marché Notre Dame"
                    onClick={() => handleCardClick(1)}
                />
                <Card
                    id="2"
                    status="Achat"
                    img={imgDetails2}
                    title="Appartement"
                    description="Appartement 4 pièces centre ville poitiers"
                    adress="Place du marché Notre Dame"
                    onClick={() => handleCardClick(2)}
                />
                <Card
                    id="3"
                    status="Achat"
                    img={imgDetails3}
                    title="Maison"
                    description="Maison 7 pièces centre ville poitiers"
                    adress="Grand'Rue"
                    onClick={() => handleCardClick(3)}
                />
                <Card
                    id="4"
                    status="Achat"
                    img={imgDetails4}
                    title="Maison"
                    description="Maison 4 pièces"
                    adress="Naintré"
                    onClick={() => handleCardClick(4)}
                />
            </div>
        </div>
    );
};

export default CurrentSales;
