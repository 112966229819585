import React, { useState } from "react";
import Pictures from "../DetailsComponents/Pictures";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details2/Photo1.jpg";
import img2 from "../../../assets/Image/Details2/Photo2.jpg";
import img3 from "../../../assets/Image/Details2/Photo3.jpg";
import img4 from "../../../assets/Image/Details2/Photo4.jpg";
import img5 from "../../../assets/Image/Details2/Photo5.jpg";
import {
    faHouseMedical,
    faLocation,
    faChessRook,
} from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];

const DetailsId2 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <Pictures
                    title="Appartement 4 pièces centre ville poitiers"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures
                    place="Place du marché Notre Dame"
                    state="197 500€"
                />
                <h5 className="text-2xl mt-20 mb-10">Points forts du bien</h5>
                <div className="mb-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faHouseMedical} text="Pas de travaux" />
                    <StrongPoint
                        icon={faLocation}
                        text="Localisation du bien"
                    />
                    <StrongPoint icon={faChessRook} text="Charme de l'ancien" />
                </div>
                <Description
                    text="L'Immobilier sur Mesure vous propose ce charmant appartement de 105m² situé au 1er étage d'un immeuble avec cachet en bon état.
                        Calme, lumineux et très bien disposé, il se compose d'un spacieux séjour avec double fenêtre, cuisine séparée aménagée et équipée, trois chambres spacieuses dont une suite parentale, salle de bain, WC.
                        Appartement actuellement loué en colocation (317€ hors charges par chambre soit 951€/mois hors charges)."
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId2;
